/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */
@import "../fonts/DMSans/stylesheet.css";
@import "./variables/index.global.scss";

@import "../../../node_modules/react-resizable/css/styles.css";

body {
    position: relative;
    color: #1f2532;
    height: 100vh;
    font-family: $default_font, sans-serif;
    overflow-y: hidden;
    padding: 0;
    margin: 0;
    letter-spacing: 0.5px;
}

#root {
    width: 100%;
    height: 100%;
    background-color: $background_color;
}

.match-parent {
    width: 100%;
    height: 100%;
}
