@primary-color: #3760c4;
@link-color: #3760c4;
@success-color: #52c41a;
@warning-color: #faad14;
@error-color: #f5222d;

@processing-color: #3760c4;
@normal-color: #d8d9df;
@white: #fff;
@black: #1f2532;

@font-size-base: 14px; // major text font size
@heading-color: rgba(15, 18, 26, 0.85); // heading text color
@text-color: rgba(31, 37, 50, 0.801); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d8d9df; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

// Layout
@layout-body-background:  #f6f7fb;
// @layout-header-background: #1f2532;


body {
    position: relative;
    color: #1f2532;
    height: 100vh;
    font-family: "dm_sansregular", sans-serif;
    overflow-y: hidden;
    padding: 0;
    margin: 0;
    letter-spacing: 0.5px;
}

.ant-popover:not(.ant-popconfirm) {
    .ant-popover-inner-content {
        padding: 0;
    }
}
