@import "../../../assets/styles/variables/fonts.global";
@import "../../../assets/styles/variables/colors.global";

.Container {
    width: 100%;
    height: 100%;
    display: flex;

    .LeftContainer,
    .RightContainer {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .InnerWrapper {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 70px;

            .MainHeader {
                font-size: 28px;
                font-family: $default_font_bold;
                line-height: 40px;
                text-align: center;
                color: black;
            }
            .Description {
                color: darken($dark_grey_color, 10);
                font-size: 14px;
            }

            .ActionsContainer {
                margin-top: 50px;
            }
        }
    }
    .RightContainer {
        padding: 20px;
        flex-shrink: 0;
        flex: 1.4;

        .WelcomeCard,
        .Card {
            width: 100%;
            height: 100%;
            box-shadow: 0 15px 25px rgba(17, 24, 59, 0.219);
            border: 1px solid rgba(17, 24, 59, 0.1);
            background-color: $white_color;
        }
        .WelcomeCard {
            height: 430px;
            max-width: 850px;
        }
    }
    .WelcomeText {
        font-size: 24px;
        margin-bottom: 15px;
        color: $primary_color;
    }
}
