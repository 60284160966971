@import "../../assets/styles/variables/fonts.global";
@import "../../assets/styles/variables/colors.global";

.Container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .HeaderContainer {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .Header {
            font-size: 24px;
            font-family: $default_font_semibold;
            display: flex;
            align-items: center;

            .SubHeader {
                font-size: 16px;
                margin-left: 5px;
                margin-right: 5px;
                position: relative;
                margin-top: 3px;
                white-space: nowrap;
                font-family: $default_font_semibold;
                color: darken($dark_grey_color, 10);

                .Icon {
                    font-size: 16px;
                }
            }
        }
        .HeaderActions {
            padding: 0;
        }
    }
    .ContentWrapper {
        padding: 0;
        flex: 1;
        padding-bottom: 15px;
        position: relative;
    }
}
