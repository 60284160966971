@import "../../assets/styles/variables/fonts.global";
@import "../../assets/styles/variables/colors.global";

.Header {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid rgba($dark_grey_color, 0.2);
    background-color: $primary_color;
    box-shadow: 0 3px 2px rgba($dark_grey_color, 0.5);

    .LogoWrapper {
        width: 150px;
    }
}

.Sidebar {
    width: 100%;
    height: 100%;
    padding: 20px 10px 10px 10px;
}

.Content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 30px;
}
