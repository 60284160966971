@import "../../../../../../assets/styles/variables/colors.global";
@import "../../../../../../assets/styles/variables/fonts.global";

.Container {
    .HeaderContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            font-size: 15px;
            font-family: $default_font_semibold;
        }
    }
    .ContentContainer {
        padding-top: 10px;
    }
}
.ModalContainer {
    padding: 0;
    :global(div.ant-modal-body) {
        padding: 0 !important;
    }
}
.InviteModalContainer {
    width: 100%;
    height: 100%;
    padding: 25px 25px 0 25px;

    .InviteModalHeader {
        padding-bottom: 10px;

        h4 {
            font-size: 16px;
            font-family: $default_font_semibold;
            span {
                color: darken($primary_color, 10);
            }
        }
        .Description {
            position: relative;
            top: -5px;
            color: rgba($dark_color, 0.5);
            display: flex;
            align-items: center;
        }
    }
    .InviteModalContent {
        padding: 10px 0 25px 0;
        position: relative;
        overflow-y: auto;

        .InviteInputGroup {
            display: flex;
            padding-bottom: 10px;
            :global(.ant-input-group-wrapper) {
                flex: 1;
            }
        }
    }
    .InviteModalFooter {
        padding-top: 25px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid rgba($dark_color, 0.1);
    }
}

.KeyboardKey {
    align-items: center;
    background: linear-gradient(-225deg, #d5dbe4, #f8f8f8);
    border-radius: 2px;
    box-shadow: inset 0 -2px 0 0 #cdcde6, inset 0 0 1px 1px #fff, 0 1px 2px 1px rgba(30, 35, 90, 0.4);
    display: flex;
    height: 18px;
    justify-content: center;
    padding-bottom: 1px;
    width: 50px;
    margin: 0 7px;
}

:global(.react-tagsinput) {
    border: 1px solid rgba($dark_color, 0.1);
    padding: 7px;
    > span {
        display: flex;
        flex-wrap: wrap;
    }

    :global(.react-tagsinput-input) {
        min-width: 150px;
        margin-bottom: 0;

        &:hover {
            border: 0;
        }
        &:focus {
            border: 0;
        }
    }
}

.InviteeTag {
    display: inline-block;
    padding: 3px 1px;
    .Content {
        display: flex;
        align-items: center;
        margin-right: 5px;
        border: 1px solid rgba($dark_color, 0.1);
        background-color: rgba($primary_color, 0.2);
        padding: 3px 7px 3px 3px;
        border-radius: 50px;

        .Avatar {
            background-color: $primary_color;
            margin-right: 7px;
        }

        .Text {
            color: darken($primary_color, 10);
        }

        .Close {
            color: $error_color;
            position: relative;
            bottom: -2px;
            margin-left: 5px;
            cursor: pointer;
            transition: all 0.1s;
            &:hover {
                transform: scale(1.1);
            }
            &:active {
                transform: scale(1.4);
            }
        }
    }
}
