@import "../../../assets/styles/variables/colors.global";
@import "../../../assets/styles/variables/fonts.global";

.SignedInButtonContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .Avatar {
        background-color: $accent_color;
    }
    .UserName {
        margin: 0 7px;
    }
    .ChevronIcon {
        font-size: 10px;
        color: $light_grey_color;
    }

    &.Disable {
        cursor: default;
    }
}

.SignedInActionsPopContainer {
    user-select: none;
    left: 50px;

    :global(.ant-popover-inner-content) {
        padding: 0 !important;
    }

    :global(.ant-popover-inner) {
        box-shadow: 0 3px 12px 2px rgba($dark_grey_color, 0.2);
        border: 1px solid rgba($dark_grey_color, 0.2);
        border-radius: 5px;
    }

    :global(div.ant-popover-content) {
        position: relative;
        top: -5px;
        :global(div.ant-popover-arrow) {
            top: -5px;
            height: 15px;
            width: 15px;
            border-top: 1px solid rgba($dark_grey_color, 0.35);
            border-left: 1px solid rgba($dark_grey_color, 0.35);
            background-color: white;
        }
    }
}

.SignedInActionsContainer {
    min-width: 240px;
    max-width: 400px;
    overflow-x: hidden;
    padding: 15px;
    user-select: none;

    .UserDetailsContainer {
        display: flex;
        width: 100%;
        border-bottom: 1px solid rgba($dark_grey_color, 0.2);
        padding-bottom: 10px;

        .Avatar {
            padding: 0;
            margin-right: 15px;

            :global(.ant-avatar) {
                background-color: $accent_color;
            }
        }

        .NameContainer {
            .Name {
                padding: 0;
                line-height: 17px;
                color: $dark_color;
            }
            .Email {
                color: darken($dark_grey_color, 10);
                font-size: $font_size_sm;
                position: relative;
                top: -2px;
            }
        }
    }

    .ExtraActionsListContainer {
        margin: 0 0 10px 0;
        position: relative;
        max-height: 350px;
        overflow-y: auto;

        .ExtraActionsItemContainer {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0 10px;
            transition: all 0.1s;
            &:first-child {
                margin-top: 10px;
            }
            &:hover {
                background-color: rgba($light_grey_color, 0.4);
            }

            &:active {
                background-color: rgba($light_grey_color, 0.7);
            }

            .Icon {
                width: 35px;
                height: 35px;
                line-height: 35px;

                :global(.anticon) {
                    font-size: 18px;
                }
            }

            .Text {
                margin-top: -4px;
            }
        }
    }
}

.AvatarContainer {
    position: relative;
    .Badge {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $error_color;
        border: 1px solid white;
        position: absolute;
        bottom: 0;
        left: 0;

        &.Online {
            background-color: $success_color;
        }
        &.Offline {
            background-color: $error_color;
        }
    }
    .ReconnectingIcon {
        position: relative;
        color: $error_color;
        font-size: 15px;
        top: -4px;
        left: -3px;
    }
}
