@import "../../../assets/styles/variables/colors.global";
@import "../../../assets/styles/variables/fonts.global";

.ErrorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;

    .ErrorTitle {
        font-size: 20px;
        color: $error_color;
        font-family: $default_font_semibold;
    }

    .ErrorText {
        font-size: 16px;
        color: $error_color;
    }
}
