@import "../../assets/styles/variables/colors.global";
@import "../../assets/styles/variables/fonts.global";

.Container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .Title {
        margin-top: 30px;
        font-size: 25px;
        font-family: $default_font_bold;
    }

    .Text {
        font-size: 16px;
        color: darken($dark_grey_color, 10);
        text-align: center;
    }
}
