@import "../../assets/styles/variables/colors.global";
@import "../../assets/styles/variables/fonts.global";

.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    user-select: none;
    position: relative;

    .MenuContainer {
        flex: 1;
        padding-top: 25px;
        padding: 35px 10px;
        overflow-y: auto;

        .DummyMenuItem {
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            justify-content: center;

            .Inner {
                background-color: rgba($white_color, 0.2);
                height: 30px;
                width: 100%;
                min-width: 40px;
                display: inline-block;
            }
        }

        .OrgName {
            font-family: $default_font_bold;
            font-size: 12px;
            position: relative;
            top: -10px;
            width: 160px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            color: lighten($success_color, 25);
            margin-bottom: 10px;
            text-align: center;
            text-transform: uppercase;
        }

        :global(.ant-menu.ant-menu-inline-collapsed) {
            width: 60px;
        }

        :global(.ant-menu-item) {
            height: 40px;
            line-height: 40px;
            border-radius: 3px;
            font-size: 13px;

            :global(.anticon) {
                font-size: 18px;
                margin-right: 0;
            }
        }

        .MenuItemBadge {
            float: right;

            :global(.ant-badge) {
                sup {
                    height: 18px;
                    line-height: 18px;
                    min-width: 22px;
                    background-color: $accent_color;
                    box-shadow: 0 0 3px rgba(#fff, 0.1);
                }
            }
        }

        .ItemIcon {
            padding: 0;

            &.HasBadge {
                position: relative;
                &::after {
                    content: "";
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: $accent_color;
                    top: 5px;
                    right: -10px;
                }
            }
        }
    }
    .BottomMenuContainer {
        padding: 0;
    }
}
