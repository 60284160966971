@import "../../../assets/styles/variables/colors.global";
@import "../../../assets/styles/variables/fonts.global";

.ContentContainer {
    padding-top: 20px;
    height: 2200px;
    overflow-y: auto;
    padding-bottom: 70px;
}

.ChoiceContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .TopBg {
        height: 200px;
        position: relative;
        z-index: -1;
        border-radius: 0 0 10px 10px;
        background-color: lighten($primary_color, 20);
    }
    .MainContent {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;

        .SubscriptionCard {
            width: 350px;
            height: 400px;
            margin: 20px;
            border-radius: 20px 20px 0 0;
            box-shadow: 0 2px 5px rgba($primary_color, 0.4);
            position: relative;
            top: -100px;
            background-color: $white_color;
            border: 1px solid lighten($primary_color, 20);
            display: flex;
            flex-direction: column;

            .Header {
                font-size: 15px;
                font-family: $default_font_bold;
                text-align: center;
                padding: 25px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .HeaderLine {
                    height: 5px;
                    width: 50px;
                    background-color: $primary_color;
                }
            }
            .Content {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }

            .Features {
                padding: 5px 15px;
                background-color: rgba($primary_color, 0.2);
                .FeatureItem {
                    display: flex;
                    align-items: center;
                    color: darken($primary_color, 20);
                    font-size: 15px;
                    padding: 5px 0;

                    .Name {
                        margin-left: 7px;
                    }
                }
            }

            .Actions {
                padding-bottom: 20px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}
