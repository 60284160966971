@import "../../../../../assets/styles/variables/colors.global";
@import "../../../../../assets/styles/variables/fonts.global";

.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :global(.ant-input) {
        min-width: 500px;
    }

    .Header {
        font-size: 20px;
    }

    .Description {
        color: darken($dark_grey_color, 10);
        margin-bottom: 2px;
    }

    .Content {
        display: flex;
        flex-wrap: wrap;
        .MigrationSource {
            display: inline-block;
            width: 220px;
            height: 150px;
            border: 1px solid $light_grey_color;
            padding: 10px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 5px;
            margin: 20px;
            position: relative;
            cursor: pointer;
            user-select: none;

            &.Active {
                background-color: rgba($primary_color, 0.03);
                color: $primary_color;
                border: 1px solid $primary_color;

                .SelectedIndicatorIcon {
                    display: inline-block;
                }
            }

            .Name {
                margin-top: 20px;
                font-size: 12px;
                font-family: $default_font_bold;
            }

            &:hover {
                background-color: rgba($light_grey_color, 0.2);
            }

            &:active {
                background-color: rgba($light_grey_color, 0.4);
            }

            .SelectedIndicatorIcon {
                position: absolute;
                font-size: 20px;
                display: none;
                right: 7px;
                top: 7px;
            }
        }
    }

    .Actions {
        margin-top: 50px;
    }
}
