@import "../../../../../assets/styles/variables/colors.global";
@import "../../../../../assets/styles/variables/fonts.global";

.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :global(.ant-input) {
        min-width: 500px;
    }

    .Header {
        font-size: 20px;
    }

    .Description {
        color: darken($dark_grey_color, 10);
        margin-bottom: 2px;
    }

    .Actions {
        margin-top: 50px;
    }

    .Button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba($dark_grey_color, 0.2);
        background-color: rgba($dark_grey_color, 0.1);
        width: 350px;
        height: 55px;
        margin: 15px;
        padding: 0 5px;
        cursor: pointer;
        transition: all 0.2s;
        user-select: none;
        &:hover {
            border: 1px solid rgba($dark_grey_color, 0.5);
            background-color: rgba($dark_grey_color, 0.2);
        }
        &:active {
            border: 1px solid rgba($dark_grey_color, 0.7);
            background-color: rgba($dark_grey_color, 0.35);
        }
        img {
            width: 50px;
        }

        .Name {
            flex: 1;
            // text-align: right;
            margin-left: 10px;
            font-size: 16px;
            color: $primary_color;
        }

        // &:hover {
        //     background-color: rgba($dark_grey_color, 0.3);
        // }
        // &:active {
        //     background-color: rgba($dark_grey_color, 0.5);
        // }
    }
}
