@import "../../assets/styles/variables/colors.global";

.Container {
    width: 100%;
    height: 100%;

    .HeaderContainer {
        background-color: $background_color;
        padding: 0;
        position: relative;
        line-height: unset;
        height: 50px;
        // z-index: 2;
    }

    .ContentContainer {
        padding: 0;
        position: relative;
        background: url(../../assets/img/bg1.svg);
    }

    .SidebarContainer {
        padding: 0;
        position: relative;
        // z-index: 3;

        .CollapseButton {
            position: absolute;
            top: 0;
            right: 0;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            cursor: pointer;
            transform: translate(20%, -20%);
            transition: all 0.1s;
            display: flex;
            align-items: center;
            justify-content: center;
            user-select: none;

            &.Collapsed {
                border: 0px solid $dark_color;
                box-shadow: 0 3px 5px rgba($dark_color, 0.16);
                background-color: $white_color;
            }

            &.Expanded {
                border: 2px solid $dark_color;
                background-color: $background_color;
            }

            &:hover {
                background-color: darken($background_color, 10);
            }

            &:active {
                background-color: darken($background_color, 15);
            }
        }
    }
}
