@import "../../../../assets/styles/variables/colors.global";
@import "../../../../assets/styles/variables/fonts.global";

.HomeContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .Header {
        padding: 5px 30px;
        background-color: $white_color;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba($light_grey_color, 0.5);

        .LogoView {
            .ArrowRight {
                font-size: 20px;
                margin: 0 10px;
            }
            display: flex;
            align-items: center;
        }
        .CurrentAction {
            font-size: 18px;
            font-family: $default_font_semibold;
        }
    }
    .ContentContainer {
        display: flex;
        flex: 1;
        height: 100%;

        .StepsView {
            width: 300px;
            position: relative;

            .InnerSteps :global(.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot) {
                background: rgba(0, 0, 0, 0.25);
            }

            .StepsContent {
                padding: 25px;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                overflow-y: auto;
            }
        }
        .MainContent {
            flex: 1;
            position: relative;
            .MainContentWrapper {
                padding: 10px;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                overflow-y: auto;

                .Card {
                    box-shadow: 0 2px 4px rgba(17, 24, 59, 0.15);
                    border: 1px solid rgba(17, 24, 59, 0.1);
                    min-height: 100%;
                    padding: 24px;
                    background-color: $white_color;
                }
            }
        }
    }
}

.LoginContainer {
    width: 100%;
    height: 100%;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .LoginBox {
        background-color: rgba($light_grey_color, 0.1);
        width: 450px;
        padding: 50px 20px 20px 20px;
        border: 1px solid $light_grey_color;
        border-radius: 10px;
        position: relative;
    }

    .LockIndicator {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(-50%);

        .Wrapper {
            width: 50px;
            height: 50px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $dark_color;
            font-size: 28px;
            border-radius: 50%;
            color: $white_color;
        }
    }
}

.InitializationContainer {
    width: 100%;
    height: 100%;
    min-height: 100%;

    .MigrationTargetContainer {
        padding: 30px 20px;
        .Title {
            font-size: 20px;
            font-family: $default_font_semibold;
            text-align: center;
        }
        .Description {
            color: darken($light_grey_color, 20);
            text-align: center;
        }

        .MigrationTargetList {
            margin-top: 20px;
            padding-top: 20px;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            border-top: 1px solid rgba($light_grey_color, 0.7);

            .MigrationTargetItem {
                width: 250px;
                height: 150px;
                position: relative;
                border: 1px solid rgba($light_grey_color, 0.7);
                box-shadow: 0 1px 3px rgba($light_grey_color, 0.7);
                margin: 10px;
                border-radius: 5px;
                padding: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                cursor: pointer;

                .ActiveIndicator {
                    position: absolute;
                    display: none;
                    top: 5px;
                    right: 10px;
                    font-size: 18px;
                }
                .AvatarWrapper {
                    margin-bottom: 10px;
                    :global(.ant-avatar) {
                        background-color: $primary_color;
                    }
                }
                .TargetName {
                    font-family: $default_font_semibold;
                }
                &:hover {
                    background-color: rgba($light_grey_color, 0.1);
                    border: 2px solid $dark_grey_color;
                    .ActiveIndicator {
                        display: block;
                    }
                }

                &:active {
                    background-color: rgba($light_grey_color, 0.2);
                    border: 2px solid $dark_grey_color;
                }

                &.Active {
                    border: 2px solid $primary_color;
                    background-color: rgba($primary_color, 0.05);
                    color: $primary_color;
                    .ActiveIndicator {
                        display: block;
                    }
                }
            }
        }

        .Actions {
            margin-top: 50px;
            text-align: center;
        }
    }

    .InitializationInfoView {
        padding: 50px 15px;
        .Steps {
            margin-top: 80px;
            width: 100%;

            :global(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon) {
                // background-color: $success_color;
            }
            :global(.ant-steps-item-process .ant-steps-item-icon) {
                border-color: $success_color;
            }
            :global(.ant-steps-item-finish .ant-steps-item-icon) {
                border-color: $success_color;
            }
            :global(.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon) {
                color: $success_color !important;
            }
        }
    }
}

.AnimatedStatusHeader {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 30px;
    .Animation {
        position: relative;
        top: 15px;
        img {
            display: inline-block;
            height: 100px;
        }
    }
    .IressLogo,
    .AusplanLogo {
        padding: 5px 0;
    }
}

.MigrationView {
    .MigrationItems {
        margin-top: 50px;

        .StatusHeader {
            font-size: 18px;
            margin-bottom: 10px;
            color: $primary_color;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ProgressContainer {
                flex: 1;
                max-width: 500px;
                margin-left: 20px;
            }
        }
    }
}

.MigrationStepView {
    padding: 10px 0 20px 0;
    .Steps {
        width: 100%;

        :global(.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon) {
            // background-color: $success_color;
        }
        :global(.ant-steps-item-process .ant-steps-item-icon) {
            border-color: $success_color;
        }
        :global(.ant-steps-item-finish .ant-steps-item-icon) {
            border-color: $success_color;
        }
        :global(.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon) {
            color: $success_color !important;
        }
    }
}

.FinalizeContainer {
    width: 100%;
    height: 100%;
    position: relative;

    .DeletingRecordItem {
        background-color: rgba($error_color, 0.1);
    }

    .Title {
        margin-bottom: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba($light_grey_color, 0.4);
        .ActionContainer {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .Info {
                font-size: 20px;
                font-weight: $default_font_bold;
            }
        }
        .Description {
            color: $dark_grey_color;
            margin-top: 7px;
        }
    }
}

.ReferenceMappingContainer {
    width: 100%;
    height: 100%;

    .ItemContainer {
        width: 100%;

        &:not(:last-child) {
            margin-bottom: 50px;
        }

        .Heading {
            font-size: 17px;
            font-family: $default_font_semibold;
            color: $primary_color;
        }

        .Content {
            margin-top: 10px;
        }

        .MapItemWrapper {
            display: flex;
            align-items: center;
            padding: 7px 15px;
            background-color: rgba($light_grey_color, 0.2);
            margin-bottom: 5px;

            .Name {
                font-family: $default_font_semibold;
                font-size: 15px;
                min-width: 150px;
            }

            .ArrowRight {
                margin: 0 15px;
            }

            .SelectContainer {
                width: 400px;
            }
        }
    }
}
