@import "../../assets/styles/variables/fonts.global";
@import "../../assets/styles/variables/colors.global";

.Container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 0 20px;
    border-bottom: 1px solid rgba($dark_grey_color, 0.2);
    background-color: $primary_color;
    box-shadow: 0 3px 3px rgba($dark_grey_color, 0.3);
    color: $light_grey_color;

    .LogoWrapper {
        width: 150px;
    }

    .HeaderActions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-left: 20px;
        flex: 1;

        .Notifications {
            padding: 0 30px;
        }

        .GlobalSearch {
            flex: 1;
        }
    }

    .UserAccountBtn {
        padding: 0 10px 0 5px;
    }
}
