@import "../../assets/styles/variables/colors.global";
@import "../../assets/styles/variables/fonts.global";

.ObjectViewContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 0 25px 20px 25px;
    .InnerWrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
    .ObjectContentWrapper,
    .TextContentWrapper {
        padding: 0;
        width: 100%;
    }
    .TextContentWrapper {
        font-size: 15px;
        color: darken($dark_grey_color, 15);
    }

    .EmailContentWrapper {
        width: 100%;

        .Subject {
            font-size: 16px;
            font-family: $default_font_bold;
            margin-bottom: 15px;
            background-color: rgba($light_grey_color, 0.3);
            padding: 2px 5px;

            >i {
                font-size: 14px;
                color: $dark_grey_color;
                margin-right: 10px;
            }
        }

        .EmailContent {
            width: 100%;
        }

        .AttachmentsContainer {
            margin-top: 25px;

            .Title {
                font-size: 15px;
                font-family: $default_font_bold;
                border-bottom: 1px solid $light_grey_color;
                padding-bottom: 3px;
                margin-bottom: 7px;
            }

            .AttachmentItem {
                padding: 7px 10px;
                background-color: rgba($light_grey_color, 0.3);
                margin-bottom: 5px;
                font-family: $default_font_semibold;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: $primary_color;

                .SizeView {
                    color: $dark_color;
                    display: inline-block;
                    padding: 1px 5px;
                    border-radius: 5px;
                    background-color: rgba($light_grey_color, 0.7);
                    font-family: $default_font_italic;
                    margin-left: 10px;
                }
                .ActionView {
                    padding: 0;
                }
            }
        }
    }
}
