@dark-grey: #9ba8bb;
@calendar-table-border-color: rgba(red(@dark-grey), green(@dark-grey), blue(@dark-grey), 0.4);
@calendar-table-border: 1px solid @calendar-table-border-color;
@calendar-popover-header-color: @dark-grey;
@calendar-popover-body-color: #fff;
@calendar-agenda-title-bg-color: #e5e5e5;
@calendar-agenda-item-hover-color: fade(@dark-grey, 5%);

.fc-theme-ant {
    & a:not([href]) {
        color: inherit; // natural color for navlinks
    }

    // reduce the size of buttons a little. 
    // 'default' is a bit large and 'small' is a bit too small
    .ant-btn {
        height: 30px;
        padding: 2px 10px;
    }

    .fc-scrollgrid, .fc-scroller > tr.fc-list-event, .fc-timegrid-col, .fc-scrollgrid-section, .fc-timegrid-divider, .fc-list, .fc-list tr.fc-list-event {
        border: @calendar-table-border;
    }
    
    .fc-col-header {
        th {
            border: @calendar-table-border;
            border-bottom-width: 2px;
        }
    }

    .fc-list-table {
        tbody th{
            background-color: @calendar-agenda-title-bg-color !important;
        }
        tr.fc-list-event {
            border-left: none;
            border-right: none;
            &:hover {
                td{
                    background-color: @calendar-agenda-item-hover-color;
                }
            }
        }
    }


    // week view
    .fc-timegrid-slot {
        &:not(.fc-timegrid-slot-minor) {
            border: @calendar-table-border;
            border-bottom: none;
        }
    }
    .fc-timegrid-slot-minor {
        border: @calendar-table-border;
        border-top-style: dotted;
    }

    // divider on week view
    .fc-timegrid-divider {
        background-color: lighten(@calendar-table-border-color, 10);
    }

    .fc-event {
        &.event-has-errors {
            border: 2px solid @error-color !important;
           .error-indicator {
                position: absolute;
                top: -10px;
                right: -10px;
                color: @error-color;
                z-index: 3;

                .anticon {
                    font-size: 16px;
                }
           } 
        }
    }

    .fc-daygrid-day {
        position: relative;
        border: @calendar-table-border;
        .fc-highlight {
            background-color: fade(@primary-color, 7%);
            border: @calendar-table-border;
            border-top: 2px solid fade(@primary-color, 25%);
        }

        .fc-daygrid-day-number {
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            &:hover {
                background-color: rgba(red(@dark-grey), green(@dark-grey), blue(@dark-grey), 0.3);
                text-decoration: none;
            }
            &:active {
                background-color: rgba(red(@dark-grey), green(@dark-grey), blue(@dark-grey), 0.45);
            }
        }
    }

    .popover {
        box-shadow: @box-shadow-base;
        border: @calendar-table-border;
        z-index: 2 !important;
        .popover-header {
            background-color: @calendar-popover-header-color;
            border-bottom: @calendar-table-border;
        }
        .popover-body {
            background-color: @calendar-popover-body-color;
        }
    }
}