@import "../../../assets/styles/variables/colors.global";
@import "../../../assets/styles/variables/fonts.global";

.NotfBtnContainer {
    display: inline-block;
    user-select: none;

    .Icon {
        font-size: 18px;
        color: $light_grey_color;
        cursor: pointer;
        &:hover {
            color: darken($light_grey_color, 15);
        }
        &:active {
            color: darken($light_grey_color, 25);
        }
    }
}

.NotfPopContainer {
    user-select: none;

    :global(.ant-popover-inner-content) {
        padding: 0 !important;
        position: relative;
        max-height: 400px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    :global(.ant-popover-inner) {
        box-shadow: 0 3px 12px 2px rgba($dark_grey_color, 0.2);
        border: 1px solid rgba($dark_grey_color, 0.2);
        border-radius: 5px;
    }

    :global(div.ant-popover-content) {
        position: relative;
        top: 5px;
        :global(div.ant-popover-arrow) {
            top: -5px;
            height: 15px;
            width: 15px;
            // transform: translateX(35px) rotate(45deg);
            // left: unset;
            display: none;
            border-top: 1px solid rgba($dark_grey_color, 0.35);
            border-left: 1px solid rgba($dark_grey_color, 0.35);
            background-color: white;
        }
    }

    .NotfPopTitle {
        font-size: 12px;
        font-family: $default_font_semibold;
        color: darken($dark_grey_color, 10);
        bottom: -2px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .TotalCount {
            display: inline-block;
            text-align: center;
            line-height: 20px;
            background-color: $dark_grey_color;
            border-radius: 50%;
            color: $white_color;
            margin-left: 10px;
            height: 20px;
            font-size: 10px;
            min-width: 20px;
        }
    }
}
.NotfListContainer {
    user-select: none;
    width: 400px;
    padding: 10px 0;

    .Placeholder {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        .Text {
            margin-top: 10px;
        }
        img {
            width: 150px;
        }
    }
}

:global(.ant-badge-count) {
    background-color: $accent_color;
}
