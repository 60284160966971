@import "../../assets/styles/variables/colors.global";

.Container {
    width: 100%;
    height: 100%;
    // background: url(../../assets/img/bg1.png);
    background-size: cover;
    background-position: center;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($background_color, 0.7);
        z-index: 0;
    }

    .ContentWrapper {
        position: relative;
        z-index: 1;
        height: 100%;
    }
}
