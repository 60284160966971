.ant-modal-root {
    .ant-modal {
        .ant-modal-content {
            box-shadow: 0 1px 5px 2px rgba(60, 50, 60, 0.2);
            border: 1px solid rgba(60, 50, 60, 0.15);
        }
    }
    .ant-modal-mask {
        background-color: rgba(0, 0, 0, 0.15);
    }
}
