@import "../../../assets/styles/variables/colors.global";
@import "../../../assets/styles/variables/fonts.global";

.Container {
    .LoginBoxContainer {
        width: 400px;
        border-radius: 20px 20px 0 0;

        :global(.ant-card-body) {
            padding: 0;
        }

        .LoginBoxHeader {
            border-radius: 20px 20px 0 0;
            background-color: $primary_color;
            padding: 30px 0 70px 0;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            color: $light_grey_color;
            position: relative;

            .WelcomeText {
                font-size: 20px;
                font-family: $default_font_bold;
            }

            .SigninText {
                padding: 0;
            }

            .LogoWrapper {
                position: absolute;
                width: 100px;
                height: 100px;
                background-color: $white_color;
                bottom: 0;
                left: 50%;
                padding: 10px;
                transform: translate(-50%, 50%);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 2px solid $primary_color;
            }
        }
        .ActionWrapper {
            padding: 100px 0 50px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .SignupPrompt {
        display: flex;
        align-items: center;
        padding-top: 20px;
        justify-content: center;

        .Text {
            margin-right: 10px;
        }
    }

    .ErrorAlert {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}
