@import "../../../assets/styles/variables/colors.global";
@import "../../../assets/styles/variables/fonts.global";

.Container {
    position: relative;
    width: 100%;
    height: 100%;

    .SearchContainer {
        position: absolute;
        display: flex;
        justify-content: flex-end;
        top: 0;
        transform: translate(0, -50%);
        right: 0;
        width: 100%;
        max-width: 700px;
        &.Active {
            z-index: 2001;
        }
        .Input {
            height: 35px;
            width: 300px;
            border-radius: 5px;
            box-shadow: 1px 1px 3px rgba(60, 40, 70, 0.3);
            transition: all 0.2s;
            position: relative;

            &.Active {
                height: 42px;
                z-index: 2002;
                width: 100%;
                box-shadow: 1px 1px 3px rgba(60, 40, 70, 0.5);
            }
        }
    }
}

.ResultsOverlay {
    width: 0;
    height: 0;
    &.Active {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: fixed;
        z-index: 2000;
        background-color: rgba($dark_color, 0.3);
    }
}

.ResultsViewPopover {
    width: 100%;
    padding-top: 40px;
}

.NoResultContainer {
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ResultsView {
    max-height: 500px;
    overflow-y: auto;
    user-select: none;
    .CategoryList {
        margin-bottom: 15px;
        :global(.ant-tag) {
            cursor: pointer;
            &:hover:not(:global(.ant-tag-blue)) {
                background-color: rgba($light_grey_color, 0.5);
            }
        }
    }
    .ResultsGroup {
        display: flex;
        align-items: flex-start;
    }
    .ItemHeader {
        width: 100px;
        padding-right: 10px;

        text-align: right;
    }
    .ItemList {
        margin-left: 10px;
        padding-left: 10px;
        width: 100%;
        flex: 1;

        .ItemListValueContainer {
            cursor: pointer;
            max-height: 75px;
            padding: 7px 10px;
            margin-bottom: 7px;
            border-left: 2px solid rgba($dark_color, 0.3);
            border-radius: 3px 0 0 3px;

            &:hover {
                background-color: rgba($light_grey_color, 0.2);
            }

            &:active {
                background-color: rgba($light_grey_color, 0.4);
            }

            .MatchValueContainer {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .MatchValue {
                    font-size: 15px;
                    font-family: $default_font_semibold;
                    white-space: nowrap;
                }
                .MatchField {
                    margin-right: 15px;
                }
            }
            .MatchEntityContainer {
                display: flex;
                align-items: center;
                margin-top: 2px;
                .Icon {
                    padding: 0;
                    margin-right: 7px;
                }
                .Name {
                    color: $primary_color;
                }
            }
        }
    }
}

.SectionHead {
    margin-bottom: 5px;
    border-bottom: 1px solid rgba($light_grey_color, 0.4);
    font-size: 12px;
    font-family: $default_font_semibold;
    color: $primary_color;
    margin-bottom: 10px;
}
