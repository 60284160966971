/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on April 12, 2021 */

@font-face {
    font-family: 'dm_sansbold_italic';
    src: url('dmsans-bolditalic-webfont.woff2') format('woff2'), url('dmsans-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dm_sansbold';
    src: url('dmsans-bold-webfont.woff2') format('woff2'), url('dmsans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dm_sansmedium_italic';
    src: url('dmsans-mediumitalic-webfont.woff2') format('woff2'), url('dmsans-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dm_sansmedium';
    src: url('dmsans-medium-webfont.woff2') format('woff2'), url('dmsans-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dm_sansitalic';
    src: url('dmsans-italic-webfont.woff2') format('woff2'), url('dmsans-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'dm_sansregular';
    src: url('dmsans-regular-webfont.woff2') format('woff2'), url('dmsans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
