$primary_color: #3760c4;
$accent_color: #e13697;
$success_color: #40c475;
$warning_color: #faad14;
$error_color: #f5222d;
$dark_color: #1f2532;
$background_color: #f6f7fb;
$white_color: white;
$dark_grey_color: #9ba8bb;
$light_grey_color: #d8d9df;
$component_background_color: #fff;
