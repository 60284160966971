@import "../../../../../assets/styles/variables/colors.global";
@import "../../../../../assets/styles/variables/fonts.global";

.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    :global(.ant-input) {
        min-width: 500px;
    }

    .Header {
        font-size: 20px;
    }

    .Description {
        color: darken($dark_grey_color, 10);
        margin-bottom: 2px;
    }

    .Actions {
        margin-top: 50px;
    }
}
