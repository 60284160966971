.Container {
    width: 100%;
    height: 100%;

    .ActionsWrapper {
        position: absolute;
        bottom: -35px;
        right: 30px;
        padding-top: 10px;
    }
}
