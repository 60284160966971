@import "../../../../assets/styles/variables/fonts.global";

.Container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .StepsView {
        padding: 20px 30px;
        width: 100%;
    }

    .Main {
        flex: 1;
        display: flex;
        width: 100%;
        .LeftContainer {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            .Heading {
                font-size: 25px;
                font-family: $default_font_bold;
                color: black;
            }
            .Illustration {
                padding: 0;
            }
        }
        .RightContainer {
            flex: 1.5;
            height: 100%;
            padding: 10px;
        }
    }
}
