@import "../../../assets/styles/variables/colors.global";
@import "../../../assets/styles/variables/fonts.global";

.ContentContainer {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;

    .UpdatePasswordBox {
        width: 450px;

        .HeaderText {
            font-size: 16px;
            font-family: $default_font_semibold;
        }
        .UserName {
            color: $primary_color;
            text-align: center;
        }
        .Desc {
            font-family: $default_font;
            text-align: center;
            font-size: 14px;
        }
    }

    .FormContainer {
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $light_grey_color;
    }
}
