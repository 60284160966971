@import "../../../assets/styles/variables/colors.global";

.Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .NameAvatarWrapper {
        display: flex;
        align-items: center;
        flex: 1;

        .UserAvatarWrapper {
            margin-right: 7px;
            display: flex;
            align-items: center;

            .Icon {
                padding: 0;
            }
        }

        .DisplayName {
            // position: relative;
            // height: 30px;
            flex: 1;

            .Inner {
                padding: 0;
                // position: absolute;
                // top: 0;
                // left: 0;
                // width: 100%;
                // height: 100%;
                // text-overflow: ellipsis;
                // white-space: nowrap;
                // overflow: hidden;
                // padding: 0;
                // display: flex;
                // align-items: center;
            }
        }
    }

    .UserTypeTag {
        margin-left: 15px;
    }

    &.IsLink {
        .DisplayName {
            color: $primary_color;

            &:hover {
                color: darken($primary_color, 10);
            }
        }
        cursor: pointer;
    }
}

.NotFoundText {
    color: #b71c1c;
}
