@import "../../../../../assets/styles/variables/colors.global";

.Container {
    padding: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .Header {
        font-size: 24px;
        color: $dark_color;
    }
    .Description {
        color: $dark_grey_color;
        margin-bottom: 20px;
    }
    .InviteContainer {
        margin-top: 5px;
    }
}
